import { useRouter } from 'next/router';
import qs from 'query-string';
import { useMemo, useContext, useCallback, Fragment } from 'react';
import { useIntl } from 'react-intl';

import { PrivacyOptions } from '@calm-web/icons';

import {
	CookiePreferencesBannerContext,
	COOKIE_PREFERENCES_BANNER_ID,
} from '@/components/app/layout/CookiePreferencesBannerProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useDeviceState, useThemeState, useLocaleState } from '@/hooks/store';
import { getIsUSA } from '@/utils/admin';

import FacebookIcon from 'icons/social-facebook.svg';
import InstagramIcon from 'icons/social-instagram.svg';
import TwitterIcon from 'icons/social-twitter.svg';

import { getFooterNavItems, socials } from './data';
import messages from './messages';
import {
	Copyright,
	FooterItem,
	FooterColumn,
	FooterHeader,
	FooterList,
	SocialIcon,
	SocialIconsColumn,
	TiCLink,
	Wrapper,
	ColumnGrid,
} from './styles';
import { FooterNavItem, FooterProps, SocialMediaItem } from './types';

/**
 *
 * Displays a Nav bar with various i18n links in it
 *
 */
const Footer = ({ initialTheme }: FooterProps) => {
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const { isOpen, setCanBeShown, setIsAnnounced, setIsForciblyShown } = useContext(
		CookiePreferencesBannerContext,
	);
	const locale = useLocaleState();
	const theme = useThemeState();
	const device = useDeviceState();
	const { query, push, pathname } = useRouter();
	const queryString = Object.keys(query).length ? `?${qs.stringify(query)}` : '';

	const onNavItemClick = async (item: FooterNavItem): Promise<void> => {
		const { name, newWindow, href } = item;

		if (!href) {
			return;
		}

		logEvent({
			eventName: 'Footer : Link : Clicked',
			eventProps: {
				name: name.defaultMessage as string,
			},
		});

		if (newWindow) {
			window.open(href);
		} else {
			await push(href);
		}
	};

	const getSocialIcon = (socialName: string) => {
		switch (socialName) {
			case 'Facebook':
				return <FacebookIcon />;
			case 'Instagram':
				return <InstagramIcon />;
			case 'Twitter':
				return <TwitterIcon />;
			default:
				return null;
		}
	};

	const handleSocialIconInteraction = (socialItem: SocialMediaItem): Promise<void> => {
		return onNavItemClick({
			name: { defaultMessage: socialItem.name },
			newWindow: true,
			href: socialItem.href,
		});
	};

	const footerNavItems = useMemo(() => {
		return getFooterNavItems();
	}, []);

	const footerTheme = initialTheme ?? theme.footer;
	const TiCItem = {
		href: 'https://www.cigna.com/legal/compliance/machine-readable-files',
		name: { defaultMessage: 'TiC MRF' },
	};

	const showOptOutModal = useCallback(() => {
		logEvent({ eventName: 'Footer : Opt Out of Targeted Ads' });
		setCanBeShown(true);
		setIsAnnounced(true);
		setIsForciblyShown(true);
	}, [logEvent, setIsAnnounced, setIsForciblyShown, setCanBeShown]);

	return (
		<Wrapper $isMobile={device.isMobile} $theme={footerTheme}>
			<ColumnGrid allowOverflow gridEl="ul">
				{footerNavItems.map(section => (
					<FooterColumn key={section.title.defaultMessage}>
						<FooterHeader>{formatMessage(section.title)}</FooterHeader>
						<FooterList>
							{section.items
								.filter(item => (item.isEnglishOnly ? locale === 'en' : true))
								.map(item => {
									const href = `${item.href}${queryString}`;
									const target = item.newWindow ? '_blank' : '';

									// Outside USA, show "Opt Out of Targeted Ads" link
									// - Use custom logic to allow tap to show cookie preferences modal
									if (item.isOptOutModal) {
										if (getIsUSA(device)) {
											// Not sure why we need a key on a fragment but it is throwing errors otherwise
											return <Fragment key="optoutmodalfragment"></Fragment>;
										}
										return (
											<li key="optoutmodal">
												<FooterItem
													tabIndex={0}
													onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && showOptOutModal()}
													onClick={showOptOutModal}
													role="button"
													aria-haspopup="dialog"
													aria-expanded={isOpen}
													aria-controls={COOKIE_PREFERENCES_BANNER_ID}
												>
													{formatMessage(item.name)}
												</FooterItem>
											</li>
										);
									}

									// In USA, show "Your Privacy Choices" link
									// - Use href to allow tap to load /optout page
									// - Show the official privacy options icon inline
									if (item.isPrivacyChoices) {
										if (!getIsUSA(device)) {
											// Not sure why we need a key on a fragment but it is throwing errors otherwise
											return <Fragment key="optoutpagefragment"></Fragment>;
										}
										return (
											<li key="optoutpage">
												<FooterItem
													onClick={(): Promise<void> => onNavItemClick(item)}
													href={href}
													target={target}
												>
													{formatMessage(item.name)}
													<PrivacyOptions fill="white" height={14} width={40} />
												</FooterItem>
											</li>
										);
									}

									return (
										<li key={item.href}>
											<FooterItem
												onClick={(): Promise<void> => onNavItemClick(item)}
												href={href}
												target={target}
											>
												{formatMessage(item.name)}
											</FooterItem>
										</li>
									);
								})}
						</FooterList>
					</FooterColumn>
				))}
				<li>
					<SocialIconsColumn $theme={footerTheme}>
						{socials.map(socialItem => (
							<li key={`social_${socialItem.name}`}>
								<SocialIcon
									aria-label={formatMessage(messages.socialIconAriaLabel, { socialMedia: socialItem.name })}
									tabIndex={0}
									role="link"
									onKeyDown={e => e.key === 'Enter' && handleSocialIconInteraction(socialItem)}
									onClick={() => handleSocialIconInteraction(socialItem)}
								>
									{getSocialIcon(socialItem.name)}
								</SocialIcon>
							</li>
						))}
					</SocialIconsColumn>
				</li>
			</ColumnGrid>
			<Copyright $theme={footerTheme}>
				{formatMessage(messages.copyright, {
					year: new Date().getFullYear(),
				})}
			</Copyright>
			{pathname === '/careers' && (
				<TiCLink $theme={footerTheme}>
					{/* eslint-disable @calm/react-intl/missing-formatted-message */}
					<FooterItem onClick={() => onNavItemClick(TiCItem)} href={TiCItem.href}>
						TiC MRF
					</FooterItem>
					{/* eslint-enable @calm/react-intl/missing-formatted-message */}
				</TiCLink>
			)}
		</Wrapper>
	);
};

export default Footer;
