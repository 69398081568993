import { useIntl } from 'react-intl';

import messages from './messages';
import { Id, PxCaptcha, Wrapper } from './styles';

function RecaptchaForm(): JSX.Element {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<h5>{formatMessage(messages.title)}</h5>
			<PxCaptcha id="px-captcha" />
			{formatMessage(messages.copy)}
			{window?._pxUuid && (
				<Id>
					{formatMessage(messages.id)} {window?._pxUuid}
				</Id>
			)}
		</Wrapper>
	);
}

export default RecaptchaForm;
