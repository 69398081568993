/* eslint-disable @calm/react-intl/missing-formatted-message */

import { MouseEvent, useState } from 'react';

import { Button } from '@calm-web/design-system';

import { useDeviceState } from '@/hooks/store';
import { removeCookie } from '@/utils/cookies';

import { ChangeMessage, FormWrapper, Note, Title } from './styles';

const Device = () => {
	const [message, setMessage] = useState('');
	const device = useDeviceState();

	async function handleDeviceUpdate(event: MouseEvent<HTMLButtonElement>): Promise<void> {
		event.preventDefault();
		removeCookie('x-device-id');
		setMessage('Updating...');
		window.location.reload();
	}

	if (!device) {
		return null;
	}

	return (
		<FormWrapper>
			<Title>Device</Title>
			<Note>
				<div>{device.deviceId}</div>
			</Note>
			<Button backgroundColor="buttonLime" legacyOnClick={handleDeviceUpdate}>
				Update Device ID
			</Button>
			{message && <ChangeMessage>{message}</ChangeMessage>}
		</FormWrapper>
	);
};

export default Device;
