import { defineMessages } from 'react-intl';

export default defineMessages({
	homepageTitle: {
		id: 'seo.homepage.title',
		defaultMessage: 'Experience Calm',
		description: 'OG title for homepage',
	},
	homepagePrimaryDescription: {
		id: 'seo.homepage.primaryDescription',
		defaultMessage:
			'Calm is the #1 app for sleep and meditation. Join the millions experiencing better sleep, lower stress, and less anxiety.',
		description: 'Primary description for homepage',
	},
	homepageDescription: {
		id: 'seo.homepage.description',
		defaultMessage:
			'Relax with Calm, a simple mindfulness meditation app that brings clarity and peace of mind into your life',
		description: 'OG description for homepage',
	},
});
