import styled from 'styled-components';
import { palette } from 'styled-tools';

import CloseSvg from 'icons/close.svg';

export const Wrapper = styled.div`
	background: ${palette('white')};
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000000;
`;

export const ExitIconWrapper = styled.div`
	align-items: center;
	display: flex;
	margin-left: auto;
	position: absolute;
	top: 24px;
	right: 24px;
`;

export const ExitIcon = styled(CloseSvg)`
	cursor: pointer;
	height: 1.5rem;
	width: 1.5rem;
`;
