import { LogEvent } from '@/components/app/layout/AnalyticsContextProvider';
import { Device } from '@/store/device/types';
import { User } from '@/store/user/types';

import { calmLogger } from './calmLogger';
import { getCookie } from './cookies';
import { postMe } from './endpoints';
import { determineComplianceLevel } from './getInitialState/initComplianceLevel';
import {
	ACCEPT_GDPR_COOKIES_KEY,
	CALM_LIMITED_DATA_USE,
	DECLINE_GDPR_COOKIES_KEY,
	declineGdprCookies,
	declineOptInCookies,
	removeAllCookies,
} from './privacyCookies';

/**
 * Checks whether Global Privacy Control (GPC) is enabled based on the
 * user's browser settings.
 *
 * @return {boolean} Returns true if Global Privacy Control (GPC) is
 * 		enabled, otherwise false.
 */
function isGPCEnabled(): boolean {
	// Aggregate GPC signal
	// User can set "do not track" (or some variant) browser preference
	const gpcSignal =
		navigator.doNotTrack ||
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(navigator as any).msDoNotTrack ||
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(navigator as any).globalPrivacyControl ||
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(navigator as any).dnt;

	// Check signal for GPC enabled
	const gpcEnabled = gpcSignal === '1' || gpcSignal === 'yes';
	return gpcEnabled;
}

/**
 * Checks if the necessary Global Privacy Control (GPC) cookies are properly
 * set based on GDPR requirements.
 *
 * @param requireGdprChoice Indicates whether GDPR choice is required.
 * @return {boolean} Returns true if the required GPC cookies are set based on
 * 		GDPR requirements, otherwise false.
 */
function areCookiesSetForGPC(requireGdprChoice: boolean): boolean {
	// Different cookies should be set depending on GDPR or not
	if (requireGdprChoice) {
		const hasAcceptGDPRCookiesFalse = Boolean(getCookie(ACCEPT_GDPR_COOKIES_KEY)) === false;
		const hasDeclineGDPRCookiesTrue = Boolean(getCookie(DECLINE_GDPR_COOKIES_KEY)) === true;
		const hasCalmLimitedDataUseUndefined = getCookie(CALM_LIMITED_DATA_USE) === undefined;
		if (hasAcceptGDPRCookiesFalse && hasDeclineGDPRCookiesTrue && hasCalmLimitedDataUseUndefined) {
			return true;
		}
	} else {
		const hasCalmLimitedDataUseCookieTrue = Boolean(getCookie(CALM_LIMITED_DATA_USE)) === true;
		const hasDeclineGDPRCookiesUndefined = getCookie(DECLINE_GDPR_COOKIES_KEY) === undefined;
		const hasAcceptGDPRCookiesUndefined = getCookie(ACCEPT_GDPR_COOKIES_KEY) === undefined;
		if (hasCalmLimitedDataUseCookieTrue && hasDeclineGDPRCookiesUndefined && hasAcceptGDPRCookiesUndefined) {
			return true;
		}
	}
	return false;
}

/**
 * Sets Global Privacy Control (GPC) cookies based on GDPR requirements.
 *
 * @param requireGdprChoice Indicates whether GDPR choice is required.
 */
function setCookiesForGPC(requireGdprChoice: boolean) {
	// Remove non-essential cookies
	removeAllCookies();
	// Set appropriate cookies
	if (requireGdprChoice) {
		declineGdprCookies();
	} else {
		declineOptInCookies();
	}
}

/**
 * Updates the Global Privacy Control (GPC) settings for a user by calling
 * /me endpoint with limited data use flag and new compliance level.
 *
 * @param user The user information (or null if no user).
 * @param requireGdprChoice Indicates whether GDPR choice is required.
 */
async function updateUserForGPC(user: User | null, requireGdprChoice: boolean) {
	// Early out if no user
	if (user === null) {
		return;
	}
	// Update user
	const newComplianceLevel = determineComplianceLevel({
		requiresGdpr: !!requireGdprChoice,
		isLimitedData: !requireGdprChoice,
		hasDeclinedGdprCookies: !!requireGdprChoice,
		isHipaaUser: user?.is_hipaa_compliant,
	});
	await postMe({
		has_opted_in_limited_data_use: true,
		compliance_level: newComplianceLevel,
	}).catch(error => calmLogger.error('Error in updateUserForGPC postMe', {}, error));
}

/**
 * Checks the Global Privacy Control (GPC) setting and, if enabled, saves
 * appropriate cookies to respect user preference to not be tracked.
 * Additionally, calls /me endpoint to update limited data use flag and
 * compliance level. Finally, logs event to note GPC was enabled.
 *
 * @param device The device information.
 * @param user The user information or null if not available.
 * @param logEvent Function for logging events.
 */
export const checkGPC = async (device: Device, user: User | null, logEvent: LogEvent) => {
	// Early out if GPC not enabled
	if (!isGPCEnabled()) {
		return;
	}

	// Early out if proper cookies are already set for GPC
	const requireGdprChoice = Boolean(device.requires_gdpr_choice);
	if (areCookiesSetForGPC(requireGdprChoice)) {
		return;
	}

	// Set cookies to respect GPC
	setCookiesForGPC(requireGdprChoice);

	// Update user to respect GPC
	await updateUserForGPC(user, requireGdprChoice);

	// Log GPC Enabled event
	logEvent({
		eventName: 'Cookie Preferences : GPC Enabled',
	});
};
