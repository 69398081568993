import styled from 'styled-components';
import { palette } from 'styled-tools';

import GearIcon from 'icons/gear.svg';

export const Wrapper = styled.div`
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	bottom: 4px;
	cursor: pointer;
	height: 40px;
	position: fixed;
	right: 4px;
	padding: 4px;
	width: 40px;
	z-index: 100000;
`;

export const Icon = styled(GearIcon)`
	fill: ${palette('white')};
`;
