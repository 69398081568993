import styled from 'styled-components';
import { palette } from 'styled-tools';

export const Wrapper = styled.div`
	color: ${palette('gray7')};
	text-align: center;
`;

export const Id = styled.div`
	padding: 16px 0;
`;

export const PxCaptcha = styled.div`
	padding: 16px 0;
	width: 100%;
`;
