/* eslint-disable @calm/react-intl/missing-formatted-message */

import { MouseEvent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@calm-web/design-system';

import AuthModal from '@/components/authForm/AuthModal';
import { AuthModalContext } from '@/context/AuthModalContextProvider';
import { useLoginForm } from '@/hooks/forms';
import { useUserState } from '@/hooks/store';
import { setAuthFormMode } from '@/store/authFormMode/actions';

import { ChangeMessage, FormWrapper, Note, Title } from './styles';

function DevTools(): JSX.Element {
	const [message, setMessage] = useState('');
	const user = useUserState();
	const { onLogout } = useLoginForm();
	const dispatch = useDispatch();
	const { setIsModalOpen } = useContext(AuthModalContext);

	function onLoginClick(event: MouseEvent<HTMLButtonElement>): void {
		event.stopPropagation();
		event.preventDefault();
		dispatch(setAuthFormMode('login'));
		if (setIsModalOpen) {
			setIsModalOpen(true);
		}
	}

	async function onAuthSuccess(): Promise<void> {
		if (setIsModalOpen) {
			setIsModalOpen(false);
		}
		setMessage('');
	}

	return (
		<FormWrapper>
			<Title>User</Title>
			{user ? (
				<Note>
					<div>{user.name}</div>
					<div>{user.email}</div>
					<div>{user.id}</div>
				</Note>
			) : (
				<Button backgroundColor="buttonLime" legacyOnClick={onLoginClick}>
					Login / Signup
				</Button>
			)}
			{message && <ChangeMessage>{message}</ChangeMessage>}
			<AuthModal onAuthSuccess={onAuthSuccess} />
			{user && (
				<Button backgroundColor="buttonLime" onPress={onLogout}>
					Logout
				</Button>
			)}
		</FormWrapper>
	);
}

export default DevTools;
