import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';

export const FormWrapper = styled.div`
	margin: 0 24px;
	padding: 24px 0;
`;

export const Title = styled.h5`
	${fontWeight(FontWeights.Demi)};
	color: ${palette('gray7')};
`;

export const Note = styled.h6`
	${fontWeight(FontWeights.Regular)};
	color: ${palette('gray4')};
	margin-bottom: 16px;
`;

export const TestSelect = styled.select`
	border: 2px solid ${palette('gray2')};
	color: ${palette('gray6')};
	border-radius: 10px;
	padding: 4px;
	height: 55px;
	max-width: 400px;
	font-size: 16px;
	margin-right: 18px;
	&:focus {
		outline: 0;
	}
`;

export const ChangeMessage = styled.h6`
	color: ${palette('blue3')};
	${fontWeight(FontWeights.Medium)};
	margin-top: 8px;
`;
