import { useRouter } from 'next/router';

import { Icon, Wrapper } from './styles';
import { GearProps } from './types';

const Gear = ({ setIsDevToolsOpen }: GearProps) => {
	const { query } = useRouter();

	function onClick() {
		setIsDevToolsOpen(true);
	}

	if (query.devtools === 'false') {
		return null;
	}

	return (
		<Wrapper onClick={onClick}>
			<Icon />
		</Wrapper>
	);
};

export default Gear;
