import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'recaptcha.form.title',
		defaultMessage: 'Please verify you are a human',
		description: 'Heading for recaptcha',
	},
	copy: {
		id: 'recaptcha.form.copy',
		defaultMessage:
			'Access to this page has been denied because we believe you are using automation tools to browse the website.',
		description: 'Copy for recaptcha',
	},
	id: {
		id: 'recaptcha.form.id',
		defaultMessage: 'Captcha ID:',
		description: 'ID copy for recaptcha',
	},
});
