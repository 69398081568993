import React, { ReactElement, useEffect } from 'react';
import { Portal } from 'react-portal';
import { useDispatch } from 'react-redux';

import { ToastNotification as PresentationalToastNotification } from '@calm-web/design-system';

import { useNotificationState } from '@/hooks/store';
import { setNotification } from '@/store/notification/actions';

// This component expects there to be a maxmimum
// of one instance present on the page at a time.
const ToastNotification = (): ReactElement | null => {
	const notification = useNotificationState();
	const dispatch = useDispatch();

	const isVisible = Boolean(notification);

	const onPress = () => {
		dispatch(setNotification(null));
	};

	// After 5 seconds, remove notification
	useEffect(() => {
		const timeout = notification
			? setTimeout(() => {
					dispatch(setNotification(null));
			  }, 5000)
			: undefined;

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [dispatch, notification]);

	useEffect(() => {
		return () => {
			dispatch(setNotification(null));
		};
	}, [dispatch]);

	if (notification === null) {
		return null;
	}

	return (
		<Portal>
			<PresentationalToastNotification
				backgroundColor={notification.backgroundColor}
				iconColor={notification.iconColor}
				fromTop={notification.position === 'top'}
				isVisible={isVisible}
				onPress={onPress}
			>
				{notification.children ?? notification.message}
			</PresentationalToastNotification>
		</Portal>
	);
};

export default ToastNotification;
