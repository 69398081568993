import getUrl from '@/utils/getUrl';

import messages from './messages';
import { FooterNavSection, SocialMediaItem } from './types';

export const getFooterNavItems = (): FooterNavSection[] => [
	{
		title: messages.company,
		items: [
			{
				href: `${getUrl('www')}/blog/about`,
				name: messages.about,
			},
			{
				href: `${getUrl('www')}/careers`,
				name: messages.careers,
			},
			{
				href: `${getUrl('www')}/blog/press`,
				name: messages.press,
			},
			{
				href: `${getUrl('www')}/mindfulness-tips`,
				name: messages.blog,
			},
			{
				href: `${getUrl('www')}/blog/tamara-levitt`,
				name: messages.instructor,
			},
			{
				href: `${getUrl('www')}/science`,
				name: messages.science,
			},
		],
	},
	{
		title: messages.offers,
		items: [
			{
				href: `${getUrl('www')}/gift`,
				name: messages.gift,
			},
			{
				href: `${getUrl('www')}/redeem`,
				name: messages.redeem,
			},
			{
				href: '/family',
				name: messages.familyPlan,
			},
			{
				href: 'https://calmhealth.com/',
				name: messages.calmHealth,
				newWindow: true,
				isEnglishOnly: true,
			},
			{
				href: `https://business.calm.com/?utm_campaign=calmdotcom_for_business_footer`,
				name: messages.business,
			},
		],
	},
	{
		title: messages.help,
		items: [
			{
				href: `${getUrl('www')}/faq/web`,
				name: messages.faq,
				newWindow: true,
			},
			{
				href: 'https://support.calm.com/hc/en-us/requests/new',
				name: messages.contact,
				newWindow: true,
			},
			{
				href: `${getUrl('www')}/terms`,
				name: messages.terms,
			},
			{
				href: '/privacy-policy',
				name: messages.privacy,
			},
			{
				href: `/privacy-policy#information-for-california-residents`,
				name: messages.californiaPrivacyNotice,
			},
			{
				name: messages.optOut,
				isOptOutModal: true,
			},
			{
				href: '/optout',
				name: messages.privacyChoices,
				isPrivacyChoices: true,
			},
			{
				href: `${getUrl('www')}/cookies`,
				name: messages.cookies,
			},
			{
				href: `${getUrl('www')}/en/accessibility-statement`,
				name: messages.accessibilityStatement,
			},
		],
	},
];

export const socials: SocialMediaItem[] = [
	{
		name: 'Facebook',
		href: `${getUrl('www')}/facebook`,
	},
	{
		name: 'Instagram',
		href: `${getUrl('www')}/instagram`,
	},
	{
		name: 'Twitter',
		href: `${getUrl('www')}/twitter`,
	},
];
