import styled from 'styled-components';
import { palette } from 'styled-tools';

import { fontWeight, FontWeights } from '@calm-web/design-system';

export const FormWrapper = styled.div`
	border-bottom: 2px ${palette('gray2')} solid;
	margin: 0 24px;
	padding: 24px 0;
`;

export const Title = styled.h5`
	${fontWeight(FontWeights.Demi)};
	color: ${palette('gray7')};
	margin-bottom: 8px;
`;

export const Note = styled.h6`
	color: ${palette('gray4')};
	${fontWeight(FontWeights.Regular)};
	margin-bottom: 16px;
`;

export const ChangeMessage = styled.h6`
	color: ${palette('blue3')};
	${fontWeight(FontWeights.Medium)};
`;
